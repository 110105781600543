export default {
    house: {
        "activityHouse": false,
        "activityHouseGuid": "",
        "agentGuid": "",
        "agentSimpleInfo": null,
        "bathRoom": 0,
        "buildingArea": 50,
        "companyGuid": "1c344d4cd44d44af90a4010688c5fc96",
        "coverImage": "https://resource.fzhitong.cn/b5b5159b-b17d-443b-8884-f387797f117a",
        "currentFloor": "中楼层",
        "decorateDesc": "精装",
        "depositStatus": 1,
        "depth": "",
        "erpCustomerDescription": "",
        "fireEscape": "",
        "floorHeight": "",
        "guid": "0896b0687c1d4190828421911c2683e0",
        "hall": 1,
        "houseLevel": 1,
        "houseNo": "FY210715018",
        "images": [],
        "increasing": "",
        "landNature": "出让",
        "leasingTaxPaye": "",
        "musicUrl": "",
        "openRoom": "",
        "orientation": "未选择",
        "presentSituation": "闲置",
        "price": 100,
        "propertyCost": "0元/㎡",
        "propertyType": "普通住房",
        "propertyTypeCode": 6,
        "rentAttention": 15,
        "rentEmployee": "0039babc998f40569c04dc4b4ccecb25",
        "rentPrice": 1000,
        "room": 2,
        "saleAttention": 15,
        "sellEmployee": "099420f0dcd045e8a9900ebb3adcd590",
        "status": 0,
        "tag": [
            "VR房源",
            "满五年",
            "随时看房"
        ],
        "title": "沃尔玛旁精装两房出租",
        "totalFloor": 5,
        "tradeType": 3,
        "unitPrice": 6600,
        "videoDespriction": "",
        "videoImageUrl": "",
        "videoUrl": "",
        "vrUrl": "https://vr.fzhitong.cn/index.html?hid=ZZW-18081357370_3dd148be89774d76b73af3b88fbbf804&domain=https://lsmsvr.fzhitong.cn:1113/VR/",
        "waterType": "专户"
    },
    newHouse: {
        "activityEstate": null,
        "activityGroupGuid": "7aaa1fbc83044fdb8d090899c697cc5c",
        "activityTips": "送抵扣券",
        "activityTitle": "送抵扣券",
        "activityType": "团购享优惠",
        "activityTypeCode": 0,
        "activityTypeDesc": "送抵扣券",
        "address": "乐山市中区世豪广场旁",
        "areas": "68-95",
        "averagePrice": 6900,
        "buildingArea": 0,
        "cityCode": 156511100,
        "coverImage": "https://resource.fzhitong.cn/FvRxbH4o2HQRZC2f7IBCG6hpN9RK",
        "depositActivity": null,
        "despriction": "",
        "erpCustomerDescription": "",
        "firstSellTime": "2020-08-02 00:00:00",
        "geographicAreaName": "[\"乐山市\",\"轻轨站区\",\"金水湾路\"]",
        "groupActivityGuid": "",
        "guid": "e3eed44a2b7f4e7f82f11008b0275a7e",
        "houseId": "",
        "houseNo": "",
        "imageUrl": "",
        "imagesGuid": "",
        "lat": 30.55533,
        "lng": 104.06139,
        "maxBuildingArea": 95,
        "minBuildingArea": 68,
        "name": "阳光·青江壹号",
        "projectAdvisor": "",
        "projectManager": "",
        "promotionName": "",
        "propertyType": 2,
        "propertyTypeDesc": "住宅",
        "regionDesc": "",
        "room1": 0,
        "room2": 2,
        "room3": 3,
        "room4": 0,
        "room5": 0,
        "room6": 0,
        "rooms": "2/3",
        "sellState": 3,
        "sellStateDesc": "售罄",
        "status": "",
        "statusCode": 0,
        "tag": "[\"VR\",\"品牌房企\",\"公交直达\",\"车位充足\",\"小户型\",\"人车分流\",\"学区房\"]",
        "tags": [
            "VR",
            "品牌房企",
            "公交直达",
            "车位充足",
            "小户型",
            "人车分流",
            "学区房"
        ],
        "totalPrices": 63,
        "url": "",
        "useLimit": "SEVENTY",
        "useLimitDesc": "70年",
        "videoUrl": "",
        "vrUrl": "https://vr.fzhitong.cn/index.html?hid=ZZW-18081357370_46493e6ab97e4f0eb12337dcc1abbac4&domain=https://lsmsvr.fzhitong.cn:1113/VR/#/"
    },
    estate: [
        {
            "address": "市中区青江路南段",
            "buildingNum": 13,
            "businessAreaName": "肖坝旅游车站",
            "completeYear": 2017,
            "coverImage": "https://resource.fzhitong.cn/559911e0-aafa-4ee4-a9b3-1d424d01e88e",
            "dealCount": 1025,
            "devCompany": "",
            "distant": 0,
            "estateUsage": "普通住房",
            "geographicAreaName": "",
            "guid": "1480e74b4bd843a9aec6554122c877f0",
            "lat": 29.566032,
            "lng": 103.715087,
            "mgtPrice": 0,
            "name": "滟澜洲朗悦里",
            "price": 7886,
            "region": "010600",
            "rentCount": 50,
            "roomNum": 6305,
            "saleCount": 532
        },
        {
            "address": "岷河二桥东岸玉桃街74号",
            "buildingNum": 17,
            "businessAreaName": "家家乐超市",
            "completeYear": 2013,
            "coverImage": "https://resource.fzhitong.cn/0eaf8ede-75df-4432-b600-7c7c3621fa7c",
            "dealCount": 631,
            "devCompany": "",
            "distant": 0,
            "estateUsage": "普通住房",
            "geographicAreaName": "",
            "guid": "59cf2744bb1c49b0bd57bf84a4c43862",
            "lat": 0,
            "lng": 0,
            "mgtPrice": 0,
            "name": "领地国际公馆",
            "price": 7385,
            "region": "010400",
            "rentCount": 52,
            "roomNum": 8502,
            "saleCount": 507
        }
    ]
}