<template>
    <div>
        <p class='header'>共 <span style="color:#FE615A;">{{total}}</span> 套 关注房源</p>
        <div class='nav'>
            <ul class='row bg'>
                <li class="nav_item cursor" :class="{'nav_item_choose':currentTab===index}" v-for="(item,index) in nav" :key="item.name"  @click="changeTab(index)">{{item.name}}</li>
            </ul>
            <div v-if="data.length">
                <!-- <ul v-if="currentTab==1">
                  <estate v-for='(item,index) in data' :key='item.guid+index' :item='item' :currentTab='currentTab'></estate>
              </ul> -->
              <ul>
                <house 
                  v-for='(item,index) in data' 
                  :key='item.guid+index' 
                  :item='item' 
                  :currentTab='currentTab'
                  @update-house-list="loadCollectionHouseList()"
                />
                </ul>
            </div>
            <el-empty v-else description="暂无关注房源" />
        </div>
        <div class="pagination">
          <el-pagination
            v-if="total > 1"
            :page-size="3"
            background
            layout="pager"
            :total="total"
            @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import Constant from "./test";
import house from "./house";
// import estate from "./estate";
import tool from "@/utils/tool.js";
import houseServer from "../../services/houseServer.js";

export default {
  name: "collectionHouse",
  data() {
    return {
      data: [],
      nav: [
        {
          name: "二手房",
          value: "二手房"
        },
        // {
        //   name: "新房",
        //   value: "新房"
        // },
        {
          name: "租房",
          value: "租房"
        }
      ],
      currentTab: 0,
      page: 1,
      total: 0,
      searchParams: {
        page: 1,
        itemsPerPage: 3
      }
    };
  },
  computed: {},
  watch: {},
  components: {
    house
    // estate
  },
  methods: {
    changeTab(index) {
      if (index !== this.currentTab) {
        this.currentTab = index;
        this.searchParams.page = 1;
        this.loadDataByType();
      }
    },
    loadDataByType() {
      switch (this.currentTab) {
        case 0:
        case 1:
          this.loadCollectionHouseList();
          break;
        default:
      }
    },
    async loadCollectionHouseList() {
      const params = {
        ...this.searchParams,
        tradeType: this.currentTab === 0 ? 2 : 1
      };
      const [
        err,
        {
          data: { records, total }
        }
      ] = await tool.to(houseServer.pageCollection(params));
      if (err) {
        console.log("pageCollection :>>", err);
        return;
      }
      this.data = records || [];
      // console.log("pageCollection :>>", JSON.stringify(records));
      if (params.page === 1) {
        this.total = total;
      }
    },
    handleCurrentChange(page) {
      this.searchParams.page = page;
      this.loadDataByType();
    }
  },
  created() {
    this.loadDataByType();
  }
};
</script>

<style scoped lang="less">
@width: 23px;
@gray: #f8f8f9;
.center {
  text-align: center;
  font-size: 16px;
}

.header {
  font-size: 30px;
  color: #101d37;
}

.nav {
  margin: @width 0;

  .bg {
    background-color: @gray;
  }

  .nav_item {
    padding: 16px 34px;
    background-color: @gray;
    .center();
  }

  .nav_item_choose {
    background-color: #e6e5e5;
  }
}
</style>