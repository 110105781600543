<template>
    <div>
        <p class='header size22 bold'>共 <span style="color:#FE615A;">{{searchRecords.length}}</span> 个 已保存搜索</p>
        <ul v-if="searchRecords.length">
          <li v-for="(search,index) in searchRecords" class="nav" :key="index">
            <div class="flex e_center" style="padding:19px 50px 19px 0">
                <p class="header size14 bold" >站点：{{search[0].value.cityName}}</p>
            </div>
            <div style="height:1px;background-color:#CCCCCC;"></div>
            <div class="list">
              <ul class=" flex">
               <li v-for="(cur,i) in search.filter((_,_i) => _i)" :key="i" class="flex">
                  <span v-if="cur.key == 'city'" class="tag">{{cur.value.name}}</span>
                  <div v-else class="flex tag">
                    <span v-for='(item,itemIndex) in cur.data' :key='itemIndex'>{{item.name }}{{itemIndex !==  cur.data.length - 1 ? "、":""}}</span>
                  </div>
               </li>
            </ul>  
            <ul class="e_start flex">
                  <li class="pr10">
                    <router-link 
                      class="black" 
                      :to="{
                        path: '/house/2',
                        query: {
                          param: searchRecords[index],
                        },
                      }"
                    >查看</router-link>  
                  </li>  
                  <li>
                    <a @click="handleDelete(index)" href="javascript:;" class="black">删除该记录</a>  
                  </li>
            </ul>
            </div>
          </li>
        </ul>
       <div v-else>
         <el-empty description="暂无保存的搜索哦~" />
       </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "collectionSearch",
  props: {},
  computed: {
    ...mapState(["searchRecords"])
  },
  methods: {
    handleDelete(index) {
      const searchRecords = this.searchRecords.filter((_, i) => i != index);
      this.$store.commit("saveSearchRecords", searchRecords);
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.center {
  text-align: center;
  font-size: 16px;
}

.header {
  color: #101d37;
}

.nav {
  margin: 23px 0;
  background-color: #f8f8f9;

  .list {
    padding: 25px 28px;

    .tag {
      display: inline-block;
      height: 32px;
      line-height: 32px;
      background: #fff;
      padding: 0 8px;
      margin: 5px;
    }

    .button {
      color: #9399a5;
      font-size: 20px;
      font-weight: 300;
      margin-left: 22px;
    }
  }
}
</style>