<template>
  <div>
    <p class='header'>我的账户信息</p>
    <p class="subHeader">如果要注销账户，请拨打 {{companyConfig.tel }}，告知客服操作</p>
    <div class='nav'>
      <p class="header" style="font-size:16px;color:#878787;margin-left:35px;">修改密码</p>
    </div>
    <div class="flex1 c_center">
      <div class="row c_center label">
        <p class="label-title">输入手机号：</p>
        <el-input :maxlength="11" class="form_item" v-model="form.mobile" @input="onInput($event,'mobile')" placeholder="请输入手机号"></el-input>
      </div>
      <div class="row c_center label">
        <p class="label-title">输入短信验证码：</p>
        <div class="flex">
          <el-input :maxlength="4" style="width:210px" class="form_item mr10" v-model="form.vaildCode" placeholder="请输入短信验证码" @input="onInput($event,'vaildCode')"></el-input>
          <el-button plain @click="onSendSms" style="width:90px;padding:0 !important;text-align:center" size="mini" :loading="isLoadingCode" :disabled="isDisabled">{{codeTitle}}</el-button>
        </div>
      </div>
      <div class="row c_center label">
        <p class="label-title">请输入新密码：</p>
        <el-input type="password" class="form_item" v-model="form.password" placeholder="请输入新密码"></el-input>
      </div>
    </div>
    <div class="column c_center" style="margin-top:40px;">
      <el-button :loading="isLoadingLogin" class="button" @click="onSubmit">{{loginTitle}}</el-button>
    </div>
  </div>
</template>

<script>
import userServer from "@/services/userServer";
import tool from "@/utils/tool.js";
import {mapState} from "vuex"
export default {
  name: "collectionSearch",
  props: {},
  data() {
    return {
      form: {
        mobile: "",
        password: "",
        vaildCode: ""
      },
      isLoadingCode: false,
      isDisabled: false,
      isLoadingLogin: false,
      codeTitle: "获取验证码",
      loginTitle: "保存修改",
      count: 60,
      timer: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState(["companyConfig"]),
  },
  watch: {},
  components: {},
  methods: {
    onSendSms() {
      if (this.isLoading) return;
      this.isLoading = true;
      const { mobile } = this.form;
      this.clearTimer();
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      let flag = this.checkMobile(mobile);
      if (!flag) {
        this.toast({ message: "手机号格式错误" });
        return;
      }
      if (this.isDisabled) return;
      this.isLoadingCode = true;
      this.isDisabled = true;
      userServer
        .sendResetPasswordCode({ value: mobile })
        .then(() => {
          this.countDown();
          this.toast({ message: "发送成功", type: "success" });
        })
        .catch(() => {
          this.toast({ message: "发送失败" });
          this.isDisabled = false;
        })
        .finally(() => {
          this.isLoadingCode = false;
        });
    },
    clearTimer() {
      this.timer && clearTimeout(this.timer);
    },
    checkMobile(phone) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(phone)) {
        return true;
      } else {
        return false;
      }
    },
    checkValidate(value) {
      var reg = /^[1-9]\d*|0$/; // 定义正则表达式
      var flag = reg.test(value); // 规则校验
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    toast({ message, type = "error", duration = 1500, onClose }) {
      this.$message({
        message,
        type,
        duration,
        onClose: () => {
          this.isLoading = false;
          onClose && onClose();
        }
      });
    },
    countDown() {
      this.timer = setTimeout(() => {
        this.count--;
        this.codeTitle = "获取验证码(" + this.count + ")";
        if (this.count == 0) {
          this.codeTitle = "获取验证码";
          this.isDisabled = false;
          this.count = 60;
        } else {
          this.countDown();
        }
      }, 1000);
    },
    onInput(value, key) {
      this.form[key] = tool.isNumber(value);
    },
    async onSubmit() {
      const { mobile, vaildCode, password } = this.form;
      if (this.isLoading) return;
      this.isLoading = true;
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      if (!vaildCode) {
        this.toast({ message: "请输入验证码" });
        return;
      }
      if (!password) {
        this.toast({ message: "请输入密码" });
        return;
      }
      if (this.checkMobile(mobile) && vaildCode.length === 4) {
        this.isLoadingLogin = true;
        this.loginTitle = "重置中···";
        try {
          const { data } = await userServer.resetPassword(this.form);
          this.toast({
            message: "重置成功!",
            type: "success",
            onClose: () => {
              this.$store.commit("saveUser", null);
              this.$router.push("/");
              this.isLoadingLogin = false;
            }
          });
        } catch (err) {
          this.toast({
            message: err.message,
            onClose: () => {
              this.isLoadingLogin = false;
            }
          });
        } finally {
          this.loginTitle = "保存修改";
          this.form = {
            ...this.form,
            vaildCode: "",
            password: ""
          };
        }
      } else {
        this.isLoadingLogin = false;
        this.toast({ message: "格式错误" });
      }
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
@green: #00ae66;
@width: 310px;
.label-title {
  width: 120px;
  text-align: left;
}
.center {
  text-align: center;
  font-size: 16px;
}

.header {
  font-size: 30px;
  color: #101d37;
}

.subHeader {
  color: #9399a5;
  font-size: 16px;
  margin-top: 8px;
}

.nav {
  margin: 23px 0;
  background-color: #f8f8f9;
  padding: 19px 50px 19px 0;
}

.label {
  margin: 25px;
}

.form_item {
  border-color: #707070;
  width: @width;
}

.button {
  padding: 14px;
  color: #ffffff;
  background-color: @green;
  width: @width;
  text-align: center;
  margin-left: 84px;
}
</style>