<template>
    <div>
        <p class='header'>共 <span style="color:#FE615A;">{{data.length}}</span> 套 关注小区</p>
        <div class='nav'>
            <ul>
                <li v-for='(item,index) in data' :key='item.guid+index'>
                    <div class="list-content row s_start">
                        <div>
                            <div>
                                <img alt="封面图" class="image-size" :src="item.coverImage" />
                            </div>
                        </div>
                        <div class="flex1">
                            <p class="title">{{item.name}}</p>
                            <ul class="row s_center ul-list">
                                <li class="ul_item">{{item.address}}</li>
                            </ul>
                            <ul class="row s_center ul-list" style="color:#9399A5;">
                                <li class="ul_item" v-if="item.businessAreaName">{{item.businessAreaName}}</li>
                            </ul>
                            <ul class="row s_center ul-list" style="color:#9399A5;">
                                <li class="ul_item" v-if="item.completeYear">{{item.completeYear+'年建'}}</li>
                                <li class="ul_item" v-if="item.estateUsage" style="color:#E6E6E6;">|</li>
                                <li class="ul_item" v-if="item.estateUsage" >{{item.estateUsage}}</li>
                                <li class="ul_item" v-if="item.propertyTypeDesc" style="color:#E6E6E6;">|</li>
                            </ul>
                            <ul class="row s_center ul-list" style="margin-top:10px;">
                                <li class="ul_item" v-if="item.saleCount" ><span class="title" style="font-size:16px;">出售 </span>{{item.saleCount}}</li>
                                <li class="ul_item" v-if="item.rentCount" style="color:#E6E6E6;">|</li>
                                <li class="ul_item" v-if="item.rentCount" ><span class="title" style="font-size:16px;">出租 </span>{{item.rentCount}}</li>
                            </ul>
                        </div>
                        <div>
                            <div class="price column c_center">
                                <p class="red-price">{{item.price||'--'}}<span style="font-size:20px;"> 元/平</span></p>
                            </div>
                        </div>
                    </div>
                    <div style="height:1px;background-color:#E6E6E6;"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Constant from "./test";

export default {
  name: "collectionEstate",
  props: {},
  data() {
    return {
      data: []
    };
  },
  computed: {},
  watch: {},
  components: {},
  methods: {
    changeTab(index) {
      if (index !== this.currentTab) {
        this.currentTab = index;
        this.loadDataByType();
      }
    },
    loadDataByType() {
      // TODO 加载
      const result = [];
      result.push(...Constant.estate);
      this.data = result;
    }
  },
  created() {
    this.loadDataByType();
  }
};
</script>

<style scoped lang="less">
.center {
  text-align: center;
  font-size: 16px;
}

.header {
  font-size: 30px;
  color: #101d37;
}

.nav {
  margin: 23px 0;
}

@width: 30px;
@height: 160px;
.list-content {
  margin: @width 0;

  .image-size {
    width: 220px;
    height: @height;
    margin-right: 30px;
    border-radius: 3px;
  }

  .title {
    font-size: 24px;
    color: #00ae66;
    font-weight: 600;
  }

  .ul-list {
    color: #101d37;
    font-size: 16px;
    font-weight: 500;
    font-family: Hiragino Sans GB;
    margin: 10px 0;

    .ul_item {
      margin-right: 15px;
    }
  }

  .tag {
    color: #849aae;
    font-weight: 300;
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 7px 21px;
    margin-right: 8px;
    margin-top: 5px;
  }

  .price {
    width: 120px;
    height: @height;

    .red-price {
      color: #fe615a;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 11px;
    }

    .gray-unit {
      color: #9399a5;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
</style>