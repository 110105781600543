<template>
  <div class="container row " style="margin-bottom:40px">
    <div>
      <div class='card' style="padding-bottom:20px;">
        <img class='avatar block' :src='avatar' />
        <span class="block account">欢迎您，{{userMobile}}</span>
        <ul style="margin-bottom:20px;">
          <li class="nav_item cursor" :class="{'nav_item_choose':currentTab===item.value}" v-for="item in nav" :key="item.name" @click="changeTab(item)">{{item.name}}</li>
        </ul>
      </div>
    </div>
    <div class='flex1 card right_card'>
      <collection-house v-if="currentTab==0"></collection-house>
      <!-- <collection-estate v-if="currentTab==1"></collection-estate> -->
      <collection-search v-if="currentTab==2"></collection-search>
      <form-edit v-if="currentTab==3"></form-edit>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/util.js";
import collectionHouse from "@/page/setting/collectionHouse";
import collectionEstate from "@/page/setting/collectionEstate";
import collectionSearch from "@/page/setting/collectionSearch";
import formEdit from "@/page/setting/formEdit";
import Constant from "../../common/Constant.js";
import { mapState } from "vuex";

export default {
  name: "setting",
  props: {},
  data() {
    return {
      nav: [
        {
          name: "关注的房源",
          value: 0
        },
        // {
        //   name: "关注的小区",
        //   value: 1
        // },
        {
          name: "我的搜索",
          value: 2
        },
        {
          name: "编辑资料",
          value: 3
        }
      ],
      currentTab: 0
    };
  },
  computed: {
    ...mapState(["user"]),
    customer() {
      return this.user ? this.user.customer : {};
    },
    userMobile() {
      return Utils.mobileTo3_1(this.customer.phone, "--");
    },
    avatar() {
      return this.customer.avatar || Constant.DEFAULT_AVATAR;
    }
  },
  watch: {
    $route: {
      handler(newVal, val) {
        const { value } = newVal.query;
        if (value) {
          this.currentTab = +value;
        }
      },
      immediate: true //第一次就监听
    }
  },
  components: {
    "collection-house": collectionHouse,
    // "collection-estate": collectionEstate,
    "collection-search": collectionSearch,
    "form-edit": formEdit
  },
  methods: {
    changeTab(item) {
      if (item.value !== this.currentTab) {
        this.currentTab = +item.value;
      }
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
@width: 44px;
@green: #00ae66;
@radius: 5px;
.center {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}
li {
  list-style: none;
}

.container {
  width: 1150px;
  margin: 38px auto 0px auto;

  .card {
    width: 190px;
    padding-top: @width;
    margin-right: 26px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: @radius;

    .avatar {
      width: 100px;
      height: 100px;
      margin: 0 @width 0;
      border-radius: @radius;
    }

    .account {
      color: #101d37;
      margin: 22px 0;
      .center();
    }

    .nav_item {
      padding: 16px;
      .center();
    }

    .nav_item_choose {
      padding: 16px;
      color: #ffffff;
      background-color: @green;
      .center();
    }
  }

  .right_card {
    padding: @width;
  }
}
</style>